
document.addEventListener("turbolinks:load", () => {

    "use strict"; // Start of use strict

    var $masthead = $('.masthead');
    var $logoCrescer = $('.logo-crescer');
    var $logoCursor = $('.logo-cursor');
    var $logoName = $('.logo-name');

    // Display an alert box after 3 seconds (3000 milliseconds):
    setTimeout(function () {

        /*--------------------------
            preloader
        ---------------------------- */
        var pre_loader = $('#preloader');
        pre_loader.fadeOut('fast', function () {
            $(this).hide();

            $masthead.show();

            /* O mastehead tem efeito de 6s vamos finalizar após 6s*/
            setTimeout(function () {
                $logoCursor.addClass('logo-cursor-pulsar');
                //$('.masthead-logo span').fadeOut('fast');
                $('.masthead-logo span').css('color', 'transparent');
                $logoCrescer.fadeIn();
            }, 3400);


        });
    }, 3000);


    // Smooth scrolling using jQuery easing
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
        if (
            location.pathname.replace(/^\//, "") ==
            this.pathname.replace(/^\//, "") &&
            location.hostname == this.hostname
        ) {
            var target = $(this.hash);
            target = target.length
                ? target
                : $("[name=" + this.hash.slice(1) + "]");
            if (target.length) {
                $("html, body").animate(
                    {
                        scrollTop: target.offset().top - 72,
                    },
                    1000,
                    "easeInOutExpo"
                );
                return false;
            }
        }
    });

    // Closes responsive menu when a scroll trigger link is clicked
    $(".js-scroll-trigger").click(function () {
        $(".navbar-collapse").collapse("hide");
    });

    // Activate scrollspy to add active class to navbar items on scroll
    $("body").scrollspy({
        target: "#mainNav",
        offset: 74,
    });

    // Collapse Navbar
    var navbarCollapse = function () {
        if ($("#mainNav").offset().top > 100) {
            $("#mainNav").addClass("navbar-shrink");
        } else {
            $("#mainNav").removeClass("navbar-shrink");
        }
    };
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(navbarCollapse);

});

