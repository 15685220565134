document.addEventListener("turbolinks:load", () => {

  $(function () {
    $(
      "#contactForm input,#contactForm textarea,#contactForm button"
    ).jqBootstrapValidation({
      preventSubmit: true,
      submitError: function ($form, event, errors) {
        // additional error messages or events
      },
      submitSuccess: function ($form, event) {
        event.preventDefault(); // prevent default submit behaviour

        var sendMessages = $form.data('sendMessage') || {};
        
        // get values from FORM
        var name = $("input#name").val();
        var firstName = $("input#name").val();; // For Success/Failure Message
        // Check for white space in name for Success/Fail message
        if (firstName.indexOf(" ") >= 0) {
          firstName = name.split(" ").slice(0, -1).join(" ");
        }
        $this = $("#sendMessageButton");
        $this.prop("disabled", true); // Disable submit button until AJAX call is complete to prevent duplicate messages
        $.ajax({
          url: $form.attr('action'),
          type: "POST",
          data: $form.serialize(),
          cache: false,
          success: function () {
            // Success message
            $("#success").html("<div class='alert alert-success'>");
            $("#success > .alert-success")
              .html(
                "<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;"
              )
              .append("</button>");
            $("#success > .alert-success").append(
              "<strong>"+ sendMessages['finish'] +"</strong>"
            );
            $("#success > .alert-success").append("</div>");
            //clear all fields
            $("#contactForm").trigger("reset");
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR.responseJSON);
            // Fail message
            $("#success").html("<div class='alert alert-danger'>");
            $("#success > .alert-danger")
              .html(
                "<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;"
              )
              .append("</button>");
            $("#success > .alert-danger").append(
              $("<strong>").text( sendMessages['error'].toString().replace(/%{name}/, firstName) )
            );
            $("#success > .alert-danger").append("</div>");
            //clear all fields
            //$("#contactForm").trigger("reset");
          },
          complete: function () {
            setTimeout(function () {
              $this.prop("disabled", false); // Re-enable submit button when AJAX call is complete
            }, 1000);
          },
        });
      },
      filter: function () {
        return $(this).is(":visible");
      },
    });

    $('a[data-toggle="tab"]').click(function (e) {
      e.preventDefault();
      $(this).tab("show");
    });
  });

  /*When clicking on Full hide fail/success boxes */
  $("#name").focus(function () {
    $("#success").html("");
  });

});
